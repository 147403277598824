<template>
  <main class="container categories">
    <section class="categories_banner">
      <div class="c_banner_text">
        <p>
          Lorem ipsum dolor sit amet consectetur <b>adipiscingeros donec</b>
        </p>
        <button class="banner_btn"><a href="#">Shop now</a></button>
      </div>
    </section>

    <section class="d-flex">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" sm="3" class="block_left">
            <h4>Filter by</h4>
            <aside class="nav-bar">
              <div class="nav_close">
                <span class="icon_close_1"></span>
                <span class="icon_close_2"></span>
              </div>
              <v-expansion-panels
                style="width: 88%; border: 1px solid #f2f2f2"
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-2">
                    Catigories
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-treeview
                      dense
                      open-all
                      :items="categories"
                      activatable
                      selection-type="independent"
                      v-model="selectedCategories"
                      return-object
                      item-children="childrens"
                    >
                      <template v-slot:label="{ item }">
                        <div
                          @click="
                            setCategories(item.title[localeLang], item.id)
                          "
                          class="v-treeview-node__label"
                          >{{ item.title.ru }}</div
                        >
                      </template>
                    </v-treeview>
                    <!-- <ul
                        class="filter_header pa-0"
                        :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
                      >
                        <li
                          v-for="categoryItem in retailCategories"
                          :key="categoryItem.id"
                          :class="{ active: activeCategory === categoryItem.title[localeLang] }"
                          class="d-flex align-center f_items mr-7"
                          @click="setCategories(categoryItem.title[localeLang], categoryItem.id)"
                        >

                          {{ categoryItem.title[localeLang] }}
                        </li>
                      </ul> -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels
                style="width: 88%; border: 1px solid #f2f2f2; margin-top: 10px"
                flat
                class="mb-1"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-2">
                    Colors
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="d-flex flex-column">
                      <div
                        v-for="(color, index) in colors"
                        :key="index"
                        @click="filterByColor(color.id)"
                        style="cursor: pointer"
                      >
                        <v-badge
                          dot
                          inline
                          :color="color.colorCode"
                          class="mr-3"
                          :class="{ bordered: color.title === 'White' }"
                        ></v-badge>
                        <span>{{ color.title }}</span>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- <v-expansion-panels
                style="width: 88%; border: 1px solid #f2f2f2"
                class="mb-1"
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-2">
                    Size
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container class="pa-0 size-filter" fluid>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input
                              class="size_choice mr-3"
                              type="checkbox"
                            /><span>One-size</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>XS</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>S</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>M</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>L</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>XL</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels> -->
              <!-- <v-expansion-panels
                style="width: 88%; border: 1px solid #f2f2f2"
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-2">
                    Price
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-range-slider
                      color="#84C897"
                      v-model="value"
                      track-color="'#E8E8E8'"
                      thumb-label="always"
                      hide-details
                    ></v-range-slider>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels> -->
            </aside>
          </v-col>
          <v-col
            sm="9"
            class="block_right"
            :class="{ 'mx-auto': $vuetify.breakpoint.smAndDown }"
          >
            <v-row no-gutters class="mb-3">
              <v-col
                cols="12"
                class="d-flex"
                :class="[
                  $vuetify.breakpoint.smAndDown
                    ? 'flex-column'
                    : 'justify-space-between',
                ]"
              >
                <div class="search-input">
                  <v-text-field
                    v-show="true"
                    v-model="search"
                    flat
                    dense
                    solo
                    placeholder="Search keywords..."
                    hide-details
                    class="rounded"
                    append-icon=""
                    background-color="#fafafa"
                    hide-no-data
                    ref="linkSearchBar"
                  />
                </div>
                <div>
                  <div
                    v-if="$vuetify.breakpoint.smAndDown"
                    class="mb-2 d-flex justify-space-between"
                  >
                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Colors</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="d-flex flex-column">
                        <div v-for="(color, index) in colors" :key="index">
                          <v-badge
                            dot
                            inline
                            :color="color.colorCode"
                            class="mr-3"
                            :class="{ bordered: color.title === 'White' }"
                          ></v-badge>
                          <span>{{ color.title }}</span>
                        </div>
                      </v-card>
                    </v-menu>
                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Size</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-container class="pa-0" fluid>
                        <v-row no-gutters>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              one size
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              xs
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              s
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              m
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              l
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              xl
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-menu>
                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Price</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <div class="pt-5">
                        <v-range-slider
                          color="#84C897"
                          v-model="value"
                          track-color="'#E8E8E8'"
                          thumb-label="always"
                          hide-details
                          :thumb-size="15"
                        ></v-range-slider>
                      </div>
                    </v-menu>
                  </div>
                  <div class="sort">
                    <!-- <span class="sort_by">Sort by:</span> -->
                    <!-- <v-menu bottom offset-y content-class="sort__menu">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :elevation="0"
                          style="border: 1px solid #f2f2f2; height: unset"
                          class="px-0"
                        >
                          <span class="mr-5">{{ sortedBy }}</span>
                          <span class="arrow_bottom"></span>
                        </v-btn>
                      </template>
                      <ul class="filter__dropdown-menu d-block pa-0">
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                      </ul>
                    </v-menu> -->
                  </div>
                </div>
              </v-col>
            </v-row>
            <div
              class="products"
              :class="{ 'd-flex': $route.query.section !== 'promotions' }"
            >
              <transition name="slide-fade" mode="out-in">
                <v-row
                  :key="$route.query.section"
                  v-if="items.length"
                  class="list_product mx-n3 w-100"
                >
                  <v-col
                    v-for="product in items"
                    :key="product.id"
                    cols="6"
                    md="4"
                    class="pa-3"
                  >
                    <ProductCard
                      :product="product"
                      :price="product.price"
                      :currency="product.currency"
                      @click.native="
                        () => {
                          $router.push({
                            name: 'Product',
                            params: { id: product.id },
                          })
                          setSelectedProduct(product)
                        }
                      "
                      @click="addToCart"
                    />
                  </v-col>
                </v-row>
                <v-row
                  :key="$route.query.section"
                  v-if="$route.query.section === 'interior'"
                  class="list_product mx-n3"
                >
                  <v-col
                    v-for="product in items"
                    :key="product.id"
                    cols="6"
                    md="4"
                    class="pa-3"
                  >
                    <ProductCard
                      :product="product"
                      :price="product.price"
                      :currency="product.currency"
                      @click.native="
                        () => {
                          $router.push({
                            name: 'Product',
                            params: { id: product.id },
                          })
                          setSelectedProduct(product)
                        }
                      "
                      @click="addToCart"
                    />
                  </v-col>
                </v-row>
                <v-row
                  :key="$route.query.section"
                  v-if="$route.query.section === 'accessories'"
                  class="list_product mx-n3"
                >
                  <v-col
                    v-for="product in items"
                    :key="product.id"
                    cols="6"
                    md="4"
                    class="pa-3"
                  >
                    <ProductCard
                      :product="product"
                      :price="product.price"
                      :currency="product.currency"
                      @click.native="
                        () => {
                          $router.push({
                            name: 'Product',
                            params: { id: product.id },
                          })
                          setSelectedProduct(product)
                        }
                      "
                      @click="addToCart"
                    />
                  </v-col>
                </v-row>
                <v-row
                  :key="$route.query.section"
                  v-if="$route.query.section === 'promotions'"
                  no-gutters
                >
                  <div class="mb-12 px-0">
                    <ul class="promotions_img row pa-0">
                      <li class="col-sm-4 col px-2">
                        <img
                          src="/img/img_categ/promotions_img/unsplash.png"
                          alt="unsplash"
                          title="unsplash"
                        />
                      </li>
                      <li class="col-sm-4 col px-2">
                        <img
                          src="/img/img_categ/promotions_img/unsplash_2.png"
                          alt="unsplash_2"
                          title="unsplash_2"
                        />
                      </li>
                      <li class="col-sm-4 col px-2">
                        <img
                          src="/img/img_categ/promotions_img/unsplash_1.png"
                          alt="unsplash_1"
                          title="unsplash_1"
                        />
                      </li>
                    </ul>
                  </div>
                  <CarouselComponent
                    title="Recommended for you"
                    product
                    :items="recommendedItems"
                    @selectItem="selectItem"
                    @addToCart="addToCart"
                  />
                  <v-container fluid class="pa-0 mb-12">
                    <h4 class="mb-3">Sale</h4>
                    <v-row class="row sale_products mx-n2 mb-12">
                      <v-col sm="4" class="sale_banner px-2">
                        <img
                          src="/img/img_categ/sale_products/banner.png"
                          alt="banner"
                          title="banner"
                        />
                        <button><a href="#">GET YOURS</a></button>
                      </v-col>
                      <v-col sm="8" class="recommended_products px-2">
                        <v-row class="ma-n2">
                          <v-col
                            v-for="product in recommendedItems.slice(0, 4)"
                            :key="product.id"
                            sm="6"
                            class="p_items pa-2"
                          >
                            <ProductCard
                              :product="product"
                              :price="product.price"
                              :currency="product.currency"
                              @click.native="
                                () => {
                                  $router.push({
                                    name: 'Product',
                                    params: { id: product.id },
                                  })
                                  setSelectedProduct(product)
                                }
                              "
                              @click="addToCart"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                  <div class="mb-12 px-0">
                    <img
                      src="/img/img_categ/banner/banner.png"
                      class="d-block w-100 mb-5 pb-2"
                      alt="banner"
                      title="banner"
                    />
                  </div>

                  <CarouselComponent
                    title="Our collections"
                    :items="collections"
                  />
                  <v-container fluid class="pa-0">
                    <h4 class="online_s">Online Shopping Made Easy</h4>
                    <v-row class="row online_shopping mx-n2">
                      <v-col class="sh_items px-2">
                        <img
                          src="/img/img_categ/O_S_img/unsplash_1.png"
                          alt="unsplash_1"
                          title="unsplash_1"
                        />
                      </v-col>
                      <v-col class="sh_items px-2">
                        <img
                          src="/img/img_categ/O_S_img/unsplash_2.png"
                          alt="unsplash_2"
                          title="unsplash_2"
                        />
                      </v-col>
                      <v-col class="sh_items px-2">
                        <img
                          src="/img/img_categ/O_S_img/unsplash_3.png"
                          alt="unsplash_3"
                          title="unsplash_3"
                        />
                      </v-col>
                    </v-row>
                  </v-container>

                  <CarouselComponent
                    title="best sellers"
                    product
                    :items="bestSellers"
                    @selectItem="selectItem"
                    @addToCart="addToCart"
                  />
                </v-row>
              </transition>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import CarouselComponent from '@/view/content/store/CarouselComponent'
import ProductCard from '@/view/content/store/ProductCard'
const { mapActions } = createNamespacedHelpers('storeCart')
const { mapState: State_Auth } = createNamespacedHelpers('auth')
const { mapState } = createNamespacedHelpers('storeProducts')
const { mapState: State_products, mapActions: Actions_products } =
  createNamespacedHelpers('products')
const {
  mapState: State_reatailCategories,
  mapActions: Actions_reatailCategories,
} = createNamespacedHelpers('retailCategories')

export default {
  name: 'Search',
  components: {
    ProductCard,
    CarouselComponent,
  },
  data() {
    return {
      search: '',
      min: -50,
      max: 90,
      value: [-20, 70],
      sortedBy: 'Sort by popularity',
      colorFilterBy: '',
      items: [],
      categories: [],
      activeCategory: '',
    }
  },
  computed: {
    ...mapState([
      'colors',
      'feltShoes',
      'interiorItems',
      'accessories',
      'recommendedItems',
      'bestSellers',
      'collections',
    ]),
    ...State_products(['products']),
    ...State_reatailCategories(['retailCategories', 'category']),
    ...State_Auth(['localeLang']),
  },
  watch: {
    retailCategories(val) {
      if (val) {
        this.categories = Object.values(val)
      }
    },
    search(val) {
      this.searchEntriesDebounced()
    },
    products(val) {
      this.items = val
    },
  },
  methods: {
    ...mapActions(['toggleSideCart', 'addCartItem', 'setSelectedProduct']),
    ...Actions_products([
      'searchProducts',
      'getAllProducts',
      'productByCatigory',
    ]),
    ...Actions_reatailCategories([
      'getAllRetailCategories',
      'getRetailCategoryBySlug',
    ]),
    selectItem(item) {
      this.setSelectedProduct(item)
      this.$router.push({ name: 'Product', params: { id: item.id } })
    },
    addToCart(item) {
      const copy = cloneDeep(item)
      copy.colors = copy.colors[0]
      copy.colors.sizes = [copy.colors.sizes[0].value]
      this.addCartItem(copy)
      this.toggleSideCart(true)
    },
    filterByColor(id) {
      const color = this.colors.find((color) => color.id === id)
      this.colorFilterBy = color.title
    },
    setCategories(category, categoryId) {
      this.activeCategory = category
      this.productByCatigory({ category_id: categoryId })
    },
    searchEntriesDebounced() {
      clearTimeout(this._timerId)

      this._timerId = setTimeout(() => {
        if (this.search.length >= 3) {
          this.searchProducts(this.search.toLowerCase())
        } else {
          this.getAllProducts()
        }
      }, 500)
    },
  },
  created() {
    if (this.$route.params.search) {
      this.search = this.$route.params.search
    } else {
      this.getAllProducts()
    }
    this.getAllRetailCategories()
    this.getRetailCategoryBySlug('asd')
  },
}
</script>
<style lang="scss" scoped>
section.categories_banner {
  margin-top: 80px;
}
.categories {
  .v-slider__thumb {
    border-radius: unset;
    width: 3px;
    left: -1.5px;
    &:before {
      content: none;
    }
  }
  .v-slider__thumb-label {
    height: 10px !important;
    width: 10px !important;
    background-color: unset !important;
    color: black;
    border-color: unset !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 8px 8px 8px 16px;
  }
  .bordered {
    .v-badge__badge {
      border: 1px solid;
      border-color: #e1e1e1 !important;
    }
  }
  .sort {
    .v-btn__content {
      font-size: 0.875rem;
      color: #666666;
      font-weight: 500;
      padding: 2px 14px 2px 10px;
      letter-spacing: normal;
    }
  }
}
.sort__menu {
  box-shadow: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  display: block;
}
.p_items {
  & > div {
    border: 1px solid #f2f2f2;
    .p_text {
      border-top: 1px solid #f2f2f2;
    }
  }
}
.v-btn {
  text-transform: unset;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: unset;
}
.v-expansion-panel-header {
  font-size: 0.875rem;
  color: #a5a5a5;
  font-weight: 500;
  padding-left: 8%;
  min-height: unset;
}
.filter_header {
  .f_items.active {
    color: #666666;
    font-weight: 700;
  }
  .f_items {
    font-weight: 500;
    cursor: pointer;
  }
}
.v-menu__content {
  background-color: #fff;
}
.size-filter {
  span {
    font-size: 14px;
    color: #666666;
    font-weight: 500;
  }
}
.sort_by {
  font-size: 0.875rem;
  color: #a5a5a5;
  font-weight: 500;
  padding-right: 10px;
}
</style>
